
:root {
    --transition-duration: 0.5;
}

html { scroll-padding-top: 80px; }

body {
    padding-top: 80px;
    font-family: 'Open Sans', sans-serif;
}

/* =========================================== Navigation ========================================== */

.logoImg {
  width: 200px;
}

.navBar {
    transition-duration: var(--transition-duration);
}

.navItem {
    font-size: 1.3em !important;
    position: relative;
    top: 14px;
    margin-left: 20px;
}

.shadowed {
    box-shadow: 0 0 16px -8px #002371;
    transition-duration: var(--transition-duration);
}


/* =========================================== Body =========================================== */

.rowGradient {
    background-image: linear-gradient(to right, #002371, #003e85, #005693, #006d9c, #0084a2);
    color: #eeeeee;
}

.rowTop {
  /*min-height: 700px;*/
}


.rowDefault {
  /*height: 700px;*/
}


/* =========================================== Welcome section =========================================== */

.primaryTagline {
    display: block;
    margin-top: 160px;
    font-size: 2.4em;
    color: #eee;
    font-weight: 400;
}

@media (max-width: 600px) {
    .primaryTagline{
        margin-top: 20px;
    }
}

.secondaryTagline {
    display: block;
    margin-top: 30px;
    font-size: 1.6em;
    color: #ccc;
    font-weight: 300;
}

.welcomeImg {
    /*display: block;*/
    width: 280px;
    margin-top: 40px;
    margin-bottom: 50px;
}


/* =========================================== About us section =========================================== */

.sergeyImg {
    width: 400px;
    margin-top: 120px;
    margin-bottom: 120px;
    border-radius: 30px;
}

.aboutUsHeader {
    display: inline-block;
    margin-top: 140px;
    margin-bottom: 60px;
    color: #777;
    font-size: 2.4em;
    font-weight: 400;
}

.aboutUsText {
    display: inline-block;
    color: #777;
    font-size: 1.6em;
    font-weight: 400;
    margin-bottom: 30px;
}


.aboutUsSignature {
    display: block;
    align-self: end;
    color: #777;
    font-size: 1.3em;
    font-weight: 400;
}

.aboutUsRole {
    display: block;
    align-self: end;
    color: #777;
    font-size: 1.3em;
    font-weight: 400;
    margin-bottom: 40px;
}

@media (max-width: 600px) {
    .sergeyImg{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .aboutUsHeader{
        margin-top: 20px;
        margin-bottom: 10px;
    }
}


/* =========================================== Products section =========================================== */

.productsHeader {
    display: inline-block;
    margin-top: 100px;
    margin-bottom: 50px;
    color: #eee;
    font-size: 2.1em;
    font-weight: 400;
}

.productsText {
    display: inline-block;
    color: #ccc;
    font-size: 1.3em;
    font-weight: 400;
    margin-bottom: 50px;
}

.productsImg {
    width: 90%;
    margin-top: 100px;
    margin-bottom: 100px;
    /*border-radius: 30px;*/
}

@media (max-width: 600px) {
    .productsHeader{
        margin-top: 20px;
        margin-bottom: 10px;
    }
    .productsImg{
        margin-top: 20px;
        margin-bottom: 10px;
    }
}


/* =========================================== Contact us section =========================================== */

.contactUsHeader {
    margin-top: 100px !important;
    margin-bottom: 20px !important;
    display: block;
}

.btn-custom {
    display: block;
    margin-top: 20px;
    margin-bottom: 100px;
    background: #005693 !important;
    color: #eee !important;
}

.invalid-feedback {
    display: block !important;
    visibility: hidden;
}

.forceDisplay {
    visibility: visible;
    text-align: end;
    float: right;
}

.formPaddedRight {
    width: 98% !important;
}

/* =========================================== Footer section =========================================== */

.footer {
    height: 80px;
}

/*.left {*/
/*    font-family: 'Open Sans', sans-serif;*/
/*}*/

/*.right {*/
/*    !*font-family: 'Open Sans', sans-serif;*!*/
/*    font-family: 'Roboto', serif*/
/*    !*font-family: 'Raleway', serif;*!*/
/*}*/
