
@import url('https://fonts.googleapis.com/css?family=Roboto');
/*@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');*/
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css?family=Raleway');

/** { padding: 0; margin: 0; box-sizing: border-box; }*/
.row {
    --bs-gutter-x: 0 !important;
    --bs-gutter-y: 0 !important;
}


@media (min-width: 1200px) {
    .container{
        max-width: 1200px !important;
    }
}
