
.privacyHeader {
    display: block;
    margin-top: 50px;
    margin-bottom: 50px;
    font-size: 2.4em;
    color: #555;
    font-weight: 400;
    align-self: auto;
}


.privacyText {
    padding-bottom: 50px;
}

.privacyText span {
    display: block;
}

.privacyText .h1 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacyText .h2 {
    margin-top: 20px;
    margin-bottom: 20px;
}

.privacyText .h3 {
    margin-top: 20px;
    margin-bottom: 20px;
}
