
.centered-content {
    display: flex;
    flex-direction: column; /* Align items vertically */
    justify-content: center; /* Center vertically */
    align-items: center; /* Center horizontally */
    text-align: center; /* Center text within the div */
    min-height: 100vh; /* Ensure the div takes up full viewport height */
}

.bottom-space {
    margin-bottom: 30px;
}
